export function formatDatetime(unformattedDate) {
  if (!unformattedDate || unformattedDate === "") {
    return ""
  }
  const date = new Date(unformattedDate)
  const dateString = formatDate(unformattedDate)
  const timeString = formatTime(date) + " Uhr"
  return dateString + " " + timeString
}

export function formatTime(dateObj) {
  if (!dateObj) {
    return ""
  } else {
    return dateObj.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
  }
}

export function formatDate(unformattedDate) {
  if (!unformattedDate || unformattedDate === "") {
    return ""
  }
  const date = new Date(unformattedDate)
  const year = date.getFullYear()
  let month = date.getMonth() + 1
  month = addZero(month)
  let day = addZero(date.getDate())
  const dayName = getDay(date)
  return dayName + ", " + day + "." + month + "." + year + " "
}

function getDay(date) {
  if (!date || !(date instanceof Date)) {
    return ""
  }
  const dayNum = date.getDay()
  const days = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"]
  return days[dayNum]
}

function addZero(str) {
  str = str.toString()
  while (str.length < 2) {
    str = "0" + str
  }
  return str
}

export function validateUrl(url) {
  if (!url) {
    return ""
  }
  if (url.startsWith("http://") || url.startsWith("https://")) {
    return url
  } else if (url.startsWith("www.")) {
    return "http://" + url
  } else if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(url)) {
    return validateEmailLink(url)
  }

  return "http://www." + url

}

export function validateEmailLink(email) {
  const emailLinkStart = "mailto:"
  if (!email) {
    return ""
  }
  if (email.startsWith(emailLinkStart)) {
    return email
  }
  return emailLinkStart + email
}

export function validatePhoneLink(phoneNumber) {
  const phoneStartLink = "tel:"
  if (!phoneNumber) {
    return ""
  }
  if (phoneNumber.startsWith(phoneStartLink)) {
    return phoneNumber
  }
  return phoneNumber + phoneStartLink
}

export function getJobwallLink(companyName) {
  const jobWallLink = "/jobwall/jobs/"
  const filter = "?company="
  if (!companyName || companyName === "") {
    return jobWallLink
  }
  const companyNameNoWhiteSpace = companyName.replace(/\s/g, "").toUpperCase()
  return jobWallLink + filter + companyNameNoWhiteSpace
}

export function getJobwallDetailLink(companyName, jobTitle, id) {
  const formattedCompanyName = companyName.replaceAll(" ", "_").replaceAll("/", "_")
  const formattedJobTitle = jobTitle.replaceAll(" ", "_").replaceAll("/", "_")
  return `/jobwall/${formattedCompanyName}/${formattedJobTitle}/${id}`
}

export function trimToNull(str) {
  if (str) {
    const trimmedStr = str.trim()
    return trimmedStr ? trimmedStr : null
  } else {
    return null
  }
}
